/* EnquiryForm.css */
.enquiry-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border:2px solid var(--darkbrown);
  }
  
  .enquiry-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color:var(--darkbrown);
  }
  
  .enquiry-form .form-group {
    margin-bottom: 15px;
  }
  
  .enquiry-form .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .enquiry-form input,
  .enquiry-form select,
  .enquiry-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .enquiry-form textarea {
    height: 120px;
  }
  
  .enquiry-form .submit-btn {
    width: 100%;
    padding: 12px;
    background-color: var(--darkbrown);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .enquiry-form .submit-btn:hover {
    background-color: var(--orange);
  }
  
  .enquiry-form .error {
    color: #ff0000;
    font-size: 12px;
    margin-top: 5px;
  }

  
  /* ======================== */
/* Contact Layout - Side by Side */
.contact-layout {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-top: 40px;
}

/* Contact Details Styling */
.contact-details {
  width: 45%;
}

.contact-heading {
  font-size: 28px;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-item {
  padding: 15px;
  background-color: var(--orange);
  border-radius: 8px;
  
}

.contact-item h3 {
  font-size: 22px;
  color: #fff;
}

.contact-item p {
  font-size: 18px;
  color:var(--darkbrown);
}

.contact-item a {
  color: var(--darkbrown);
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline;
}

/* Enquiry Form Styling */
.enquiry-form-container {
  width: 45%;
}

.enquiry-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.enquiry-form .form-group {
  display: flex;
  flex-direction: column;
}

.enquiry-form label {
  font-size: 16px;
  margin-bottom: 8px;
}

.enquiry-form input,
.enquiry-form select,
.enquiry-form textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.enquiry-form button {
  padding: 12px;
  font-size: 18px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.enquiry-form button:hover {
  background-color: #005bb5;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Responsive Styles: Stacking contact details and form vertically on mobile */
@media (max-width: 768px) {
  .contact-layout {
    flex-direction: column;
    align-items: center;
  }

  .contact-details,
  .enquiry-form-container {
    width: 100%;
  }

  .contact-heading {
    font-size: 24px;
  }
  
  .contact-item {
    padding: 12px;
  }

  .enquiry-form .form-group input,
  .enquiry-form .form-group select,
  .enquiry-form .form-group textarea {
    font-size: 14px;
    padding: 8px;
  }

  .enquiry-form button {
    font-size: 16px;
  }
}
  