/* GallerySlider.css */

.gallery-slider-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
  }
  
  .gallery-slider-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .slick-slide img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .slick-prev, .slick-next {
    z-index: 1;
  }
  
  .slick-dots {
    bottom: -40px;
  }
  