.package-section {
    padding: 40px;
    background-color: #f5f5f5;
    text-align: center;
  }
  
  .package-section .section-title {
    font-size: 2.5rem;
    color:#000;
    margin-bottom: 20px;
  }
  
  .package-section .section-description {
    font-size: 1.1rem;
    color: #000;
    margin-bottom: 30px;
  }
  
  .package-section .package-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 packages per row by default */
    gap: 20px;
    justify-content: center; /* Center the grid within the container */
    margin-bottom: 40px;
    max-width: 1200px; /* Maximum width for the grid container */
    margin-left: auto; /* Centering the container horizontally */
    margin-right: auto; /* Centering the container horizontally */
  }
  
  .package-section .package-card {
    /* background-color: var(--orange); */
    background-color: var(--yellow);
    padding: 20px;
    border-radius: 8px;
    border:2px solid #000;
    text-align: left;

  }
  
  .package-section .package-title {
    font-size: 1.5rem;
    color: #66311a;
  }
  
  .package-section .package-price {
    font-size: 1.2rem;
    color:#c94c08;
    margin: 10px 0;
  }
  
  .package-section .package-details ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .package-section .package-details li {
    font-size: 1rem;
    color: #000;
  }
  
  .package-section .wedding-package {
    background-color: var(--yellow);
    border:2px solid #000;
  }
  
  .package-section .contact-info {
    margin-top: 30px;
    font-size: 1.1rem;
    color: #000;
  }
  
  .package-section .contact-info a {
    color:#333;
    text-decoration: none;
  }
  
  .package-section .contact-info a:hover {
    text-decoration: underline;
  }
  
  /* Media query to display 2 packages in the second row for smaller screens */
  @media (max-width: 1024px) {
    .package-section .package-cards-container {
      grid-template-columns: repeat(2, 1fr); /* 2 packages per row */
    }
  }
  
  /* Media query to display 1 package per row for smaller screens */
  @media (max-width: 768px) {
    .package-section .package-cards-container {
      grid-template-columns: 1fr; /* 1 package per row */
    }
  }

.package-details{
    color:#000;
}
  