*{
   --orange: #e37b43;
   --yellow: #fbc404;
   --darkbrown:#7c442c;
}




.hotel-section {
    overflow: hidden;
    width: 100%;
    padding: 20px;
    /* background-color: #f0f0f0; */
   
  }
  
  .hotel-cards-container {
    display: flex;
    white-space: nowrap;
    animation: scroll 30s linear infinite;
  }
  
  .hotel-card {
    display: inline-block;
    width: 250px;
    margin-right: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    border:2px solid var(--lightbrown);
  }
  
  .hotel-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  
  .hotel-card p {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
    max-height: 80px; /* Limit description height to prevent overflow */
    overflow: hidden;
    text-overflow: ellipsis; /* Show ellipsis if the text is too long */
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

/* ============= faq section ================ */

.faq-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 30px; /* Space between the columns */
}

.faq-column {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.accordion-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.accordion-header {
  padding: 15px;
  background-color:var(--orange);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color:var(--darkbrown);
}

.accordion-header.active {
  background-color: #f1f1f1; /* Highlight active header */
}

.accordion-icon {
  font-size: 18px;
}

.accordion-content {
  padding: 0px 15px;
  background-color: #f5f5f5;
}

.accordion-content p {
  margin: 0;
}

/* Responsive Styles */

/* For smaller tablets and mobile screens, stack the FAQ columns vertically */
@media (max-width: 768px) {
  .faq-grid {
    grid-template-columns: 1fr; /* Stack the columns */
  }
  
  .accordion-header {
    font-size: 16px; /* Smaller font for smaller screens */
  }

  .accordion-content {
    padding: 0px 10px; /* Adjust padding */
  }
}

/* For mobile screens */
@media (max-width: 480px) {
  .accordion-header {
    font-size: 14px; /* Further reduce font size */
    padding: 12px; /* Adjust padding */
  }

  .accordion-content {
    padding: 0px 10px;
  }

  .accordion-item {
    font-size: 14px; /* Adjust font size for content */
  }
}


  /* ======================== whatsapp and phone button =============== */


.phone-float {
    position: fixed;
    transform: translate(108px, 0px);
    bottom: 3%;
    right: -1%;
    width: 200px;
    overflow: hidden;
    background-color: rgb(231, 61, 61);
    color: #FFF;
    border-radius: 2px 0 0 2px;
    z-index: 999;
    transition: all 0.5s ease-in-out;
    vertical-align: middle ;
    padding: 10px 0px;
  
  }
  
  .phone-float a span {
    color: white;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    line-height: 16px;
    font-weight: bolder;
  }
  
  .phone-float i {
    font-size: 40px;
    color: white;
    line-height: 15px;
    padding: 20px;
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
  
  
  }
  
  .phone-float:hover {
    color: #FFFFFF;
    transform: translate(0px, 0px);
  }
  
  .phone-float:hover i {
    transform: rotate(360deg);
  }
  
  
  .whats-float {
    position: fixed;
    transform: translate(-108px, 0px);
    bottom: 3%;
    left: 0;
    width: 180px;
    overflow: hidden;
    background-color: #48b737;
    color: #FFF;
    border-radius: 0 2px 2px 0;
    z-index: 999;
    transition: all 0.5s ease-in-out;
    vertical-align: middle;
    text-align: right;
    padding: 10px 0px;
  }
  
  .whats-float a span {
    color: white;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    line-height: 16px;
    font-weight: bolder;
    left: 2px;
    transform: translateX(0);
  }
  
  .whats-float i {
    font-size: 45px;
    color: white;
    line-height: 15px;
    padding: 20px;
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
    text-align: center;
    right: 10px;
  }
  
  .whats-float:hover {
    color: #FFFFFF;
    transform: translate(0px, 0px);
  }
  
  .whats-float:hover i {
    transform: rotate(360deg);
  }
  
  /* ============================= */
  

  .book-now-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: var(--orange);
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    
  }
  
  .book-now-button:hover {
    background-color: var(--darkbrown); /* Darker green on hover */
    transform: translateY(-3px); /* Slight lift effect */
  }
  
  .book-now-button:active {
    background-color:var(--darkbrown); /* Even darker green when clicked */
    transform: translateY(1px); /* Slight press effect */
  }
  
   .navbar-nav{
    margin-left:20px;
  } 
@media(max-width:992px){
  .navbar-nav{
    margin-left:0px!important;
  } 
}

.nearby-places-section {
  background-color: #e4dede;
  padding: 70px 0;
}

.section-header {
  margin-bottom: 50px;
}

.nearby-places-section .section-title {
  font-size: 2.8rem;
  color: var(--darkbrown);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: fadeIn 1s ease-out;
}


.nearby-places-section h4{
  color:var(--orange);
}

.section-subtitle {
  font-size: 1.2rem;
  color: #555;
  font-style: italic;
  margin-top: 15px;
  max-width: 800px;
  margin: 0 auto;
  animation: fadeIn 1.5s ease-out;

}

/* Card Grid */
.attractions-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;


}

.attraction-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease, box-shadow 0.4s ease;

}

.attraction-img {
  background-size: cover;
  background-position: center;
  height: 250px;
  transition: transform 0.3s ease;
}

.attraction-info {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 0 0 10px 10px;
  transition: all 0.3s ease;
}

.attraction-title {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
}

.attraction-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

/* Hover Effect */
.attraction-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.attraction-card:hover .attraction-img {
  transform: scale(1.1);
}

.attraction-card:hover .attraction-info {
  background-color: #e7f3f7;
}

/* Address Section */
.address-section {
  background-color: var(--yellow);
  color: #fff;
  padding: 40px 20px;
  border-radius: 8px;
  margin-top: 60px;
  animation: fadeIn 2s ease-out;
}

.nearby-places-section .address-section h3 {
  font-size: 2rem;
  font-weight: bold;
}

.address-section p {
  font-size: 1.1rem;
  color: var(--darkbrown);
}

/* Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* ======================= */

/* Activities Section Styling (Activities.css) */

.activities-section {
  padding: 40px 0;
  background-color: #f5f5f5;
}

.activities-section .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.activities-section .section-title {
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
  color: #333;
}

.activities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.activity-card {
  background-color: #fff;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0 4px 6px var(--orange); */
  border:1px solid var(--darkbrown);
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.activity-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.activity-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.activity-name {
  font-size: 18px;
  padding: 15px;
  background-color: var(--darkbrown);
  color: white;
  font-weight: bold;
  
}

@media (max-width: 768px) {
  .activities-section .section-title {
    font-size: 28px;
  }

  .activities-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .activities-grid {
    grid-template-columns: 1fr;
  }

  .section-title {
    font-size: 24px;
  }
}

.activity-box{
  box-shadow: 0px 0px 4px 2px var(--orange);
}



.activity-box1{
  border:2px solid var(--orange);
}


.testimonial-item1{
  border:2px solid var(--darkbrown);
}


.accordion-item{
  /* border:2px solid var(--darkbrown)!important; */
  background: var(--orange)!important;
}


.service-box{
  box-shadow: 0px 2px 0px 1px var(--orange);
  margin: 10px;
}

.text-orange{
  color:var(--orange);
}



.attraction-img img{
  width:100%;
  height:100%;
  object-fit: cover;
}

.img1box img{
  /* border:2px solid red; */
  height:650px;
}

.nav-item .nav-link{
  color:var(--orange)!important;
  font-weight: bold;
}


@media(max-width:768px){
  .nav-item .nav-link{
     border-bottom: 2px solid var(--orange);
  }
  
}


.hotel-section{
  background-color: var(--orange);
}

.hotel-section p{
  color:var(--darkbrown);
  font-weight: bold;
}


.why-us-item{
  box-shadow: 0px 3px 2px 2px var(--darkbrown);
  
}


/* ================ */
/* Basic Styles for the Carousel */
.hotel-section {
  position: relative;
  width: 100%;
  overflow: hidden; /* Hide overflow */
}

.hotel-cards-container {
  display: flex;
  flex-wrap: nowrap;
  padding: 20px 0;
  transition: transform 0.5s ease; /* Smooth scrolling */
}

.hotel-card {
  min-width: 300px; /* Adjust based on your design */
  margin-right: 20px; /* Space between cards */
  flex-shrink: 0; /* Prevent shrinking */
}

.hotel-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.hotel-card p {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

/* Media Query for responsiveness */
@media (max-width: 768px) {
  .hotel-card {
    min-width: 100%; /* Show one card at a time on mobile */
  }
}



.text-brown{
  color:var(--darkbrown);
}

.text-yellow{
  color:var(--yellow);
}

@media(max-width:768px){
  p{
    font-size: 1rem;
  }
}


.menu-btn{
 
  padding: 5px 15px;
  background-color: var(--darkbrown);
 
}

/* =========================== */


/* styles.css */
.menu-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
}

.menu-section {
  margin-bottom: 40px;
}

.menu-title {
  font-size: 2rem;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: var(--darkbrown);
  border-radius: 10px;
}

.menu-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.menu-item {
  background-color: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  border: 1px solid var(--orange);
}

.menu-item p {
  font-size: 1.2rem;
  font-weight: bold;
  color:var(--orange);
}

.menu-item:hover {
  transform: translateY(-10px);
}

@media (max-width: 768px) {
  .menu-items {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .menu-items {
    grid-template-columns: 1fr;
  }
}

.slider-text{
  font-size: 2.2rem;
}


.text-orange{
  color:var(--darkbrown);
}
