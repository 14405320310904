.gallery-item {
    position: relative;
    overflow: hidden;
  }
  
  .gallery-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .gallery-item:hover .gallery-content {
    opacity: 1;
  }
  
  .gallery-content h5 {
    color: #fff;
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .gallery-content ul {
    list-style: none;
    display: flex;
    gap: 10px;
    padding: 0;
  }
  
  .gallery-content ul li {
    display: inline;
  }
  
  .gallery-content ul li a {
    color: #fff;
    font-size: 1.5em;
  }
  