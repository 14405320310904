/* ====================== keyword based Pages styling ========================= */
.box1{
    /* border:2px solid red; */
    margin-bottom: 10px;
    border-bottom: 2px solid var(--orange);
    padding-bottom: 10px;
  }
  
  .box1 .b1{
    width:30%;
    height:60px;
  }
  .box1 .b1 img{
    width:100%;
    height:100%;
    object-fit: cover;
  }
  
  
  .box1 .b2{
    width:60%;
  }
  
  .mybox1{
    background-color: var(--brown);
    color:#fff;
  }

  .keyword-img img:hover{
    transform: scale(1.1);
    transition: 1s ease-in-out;
  }
  
  .contact-section{
    margin-top:40px;
    padding: 20px;
    /* background-color: var(--brown); */
    background-color:var(--green);
    color:#fff;
    border:2px solid var(--orange);
    background-color: var(--orange);
  }
  
  .keywordsection h2{
    font-size: 1.5rem;
    font-weight: 600;
    color:#000;
  }
  
  .mybox{
    color:#fff;
   
  }

  .mybox1{
    background-color: var(--yellow);
  }

  .mybox1 a{
    color: #000!important;
  }
  .footer-logo img{
    padding-bottom: 30px;
  }
  
  /* TestimonialsCarousel.css */
  .testimonial-carousel {
    width: 100%;
    margin: auto;
  }
  
  .testimonial-item {
    text-align: center;
    padding: 20px;
    background-color: #d3c7c7;
  
  }
  
  .testimonial-quote {
    font-style: italic;
    margin: 10px 0;
  }
  
  .testimonial-author {
    font-weight: bold;
  }



  /* ==================== */

  .mycontact-box{
    border:2px solid var(--darkbrown);
    text-align: center;
    padding: 10px;

  }


  
  .faq-question{
    border:2px solid #333;
    margin: 10px 0px;
    padding: 10px;
    background:var(--yellow);
    color:#000;
  } 


  .faq-answer{
    background: var(--yellow);
    padding: 10px;
    color:#000;
  }

  